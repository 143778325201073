import React, { useRef, useState, useEffect } from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
// import styles from "../styles/home.module.css"
import styles from "../../styles/quilmesMusicaHome.module.scss"
import { useMediaQuery } from "react-responsive"
import * as Scroll from "react-scroll"

import FondoQ from "../../images/quilmesMusica/svg/quilmes-web-02.svg"
import FondoArrancaMusica from "../../images/quilmesMusica/svg/quilmes-web-03.svg"
import LogoQ from "../../images/quilmesMusica/svg/quilmes-web-05.svg"
// import LogoQ from "../../images/test.svg"
import CervezaIzquierda from "../../images/quilmesMusica/svg/quilmes-musica-32.svg"
import CervezaDerecha from "../../images/quilmesMusica/svg/quilmes-musica-33.svg"
import { IsMobileDevice } from "../../../utils/ismobile"
import Bowser from "bowser"

let LinkTo = Scroll.Link

//LOGICA SIMILAR PARA AGREGAR IMAGEN MOBILE DESDE LOCALIMAGE

function QuilmesMusicaHome({ data }) {
  const mobile = useMediaQuery({ query: "(max-width: 485px)" })
  const [isActive, setIsActive] = useState(true)
  const [isMobile, setIsMobile] = useState(false)
  const [browserName, setBrowserName] = useState("")

  const slider = useRef(null)

  useEffect(() => {
    setIsMobile(IsMobileDevice())
    const {
      parsedResult: {
        browser: { name },
      },
    } = Bowser.getParser(window.navigator.userAgent)
    setBrowserName(name)
  }, [])

  function handleClick() {
    setIsActive(!isActive)
  }

  return (
    <div className={styles.blackOverlay}>
      <div className={styles.imageBar}>
        <div>
          <img
            id={styles.guitarraIzquierda}
            alt="guitarra izquierda"
            src={CervezaIzquierda}
          />
        </div>

        <div>
          <img id={styles.logo} alt="logo quilmes" src={LogoQ} />
        </div>

        <div>
          <img
            id={styles.guitarra}
            alt="guitarra derecha"
            src={CervezaDerecha}
          />
        </div>
      </div>

      <div className={styles.content}>
        <img
          id={styles.orejas}
          alt="Fondo quilmes musica"
          src={FondoArrancaMusica}
        />

        <div className={styles.fondoTexto}>
          {isMobile ? (
            <p
              className={
                browserName &&
                (browserName === "Firefox" || browserName === "Safari")
                  ? styles.firefoxText
                  : ""
              }
            >
              CONOCÉ TODO LO QUE VAS
              <br />
              A PODER VIVIR ESTE VERANO 2023
              <br />
              EN TODO EL PAIS
            </p>
          ) : (
            <p>
              CONOCÉ TODO LO QUE VAS A PODER VIVIR ESTE VERANO 2023 EN TODO EL
              PAIS
            </p>
          )}
        </div>

        <LinkTo to="mapaquilmes" smooth={true}>
          <button className={styles.button} onClick={()=>{
                 window.dataLayer.push({
                  event: "GAEvent",
                  event_category: "Content",
                  event_action: "Arranca la Musica",
                  event_label: "Explorar Mapa",
                  interaction: true,
                  component_name: "boton_explorar_mapa",
                  element_text: "Explorar Mapa",
                })
          }}>
            <h1>EXPLORAR MAPA</h1>{" "}
          </button>
        </LinkTo>
      </div>
    </div>
  )
}

export default QuilmesMusicaHome
