import React, {useEffect } from "react"
import "../../styles/global.css"
import SEO from "../../components/seo"
import { navigate, graphql } from "gatsby"

// Components
import LayoutMusic from "../../components/layoutMusic"
import MapSection from "../../components/quilmes-musica/MapSection.js"
import QuilmesMusicaHome from "../../components/quilmes-musica/quilmesMusicaHome"
// import { provincias } from "../../../utils/mockProvincias"

const Home = ({ data }) => {
  // const [step, setStep] = useState("")


  useEffect(() => {
    let local = localStorage.getItem("age")
    let session = sessionStorage.getItem("age")

    window.dataLayer.push({
      brand: "Quilmes",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
        window.location.search.includes("?cmp=")
          ? window.location.search.split("?cmp=")[1].split("&")[0]
          : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "Quilmes Musica 2023",
      pageType: "Basic page",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.quilmes.com.ar/quilmesfestivales",
      url_campaign: `${
        window.location.search.includes("utm_campaign=")
          ? window.location.search.split("utm_campaign=")[1]
          : ""
      }`,
      Step_number: "",
    })

    if (!session && !local)
      navigate("/agegate", { state: { newPath: "/quilmesfestivales" } })
  }, [])

  return (
    <LayoutMusic>
      <SEO title={"Quilmes"} description={""} />
      <QuilmesMusicaHome data={data} />
      <MapSection provincesEvents={data?.allEventos?.nodes} />
      {/* setStep={setStep} */}
    </LayoutMusic>
  )
}

export default Home

export const data = graphql`
  query Musica2023 {
    allEventos {
      nodes {
        id
        fecha
        imgBack
        latitud
        longitud
        localidad
        month
        provincia
        texto
        titulo
        active
      }
    }
  }
`
