import { Link, navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import logo130 from "../../../images/quilmesMusica/svg/quilmes-web-01.svg"
import cubes from "../../../images/quilmesMusica/svg/cubes.svg"
import backArrow from "../../../images/quilmesMusica/svg/quilmes-web-18.svg"
import styles from "../../../styles/headerMapMusic.module.scss"
import { IsMobileDevice } from "../../../../utils/ismobile"
import Bowser from "bowser"

const HeaderMusicMap = ({ title, text }) => {
  const [isMobile, setIsMobile] = useState(false)
  const [browserName, setBrowserName] = useState("")

  useEffect(() => {
    setIsMobile(IsMobileDevice())
    const {
      parsedResult: {
        browser: { name },
      },
    } = Bowser.getParser(window.navigator.userAgent)
    setBrowserName(name)
  }, [])
  return (
    <>
      <header className={styles.main}>
        <div>
          <Link to={`/quilmesfestivales`}>
            <img className={styles.logo} src={logo130} alt="logo130" />
          </Link>
        </div>

        {isMobile ? (
          <h1
            className={
              browserName &&
              (browserName === "Firefox" || browserName === "Safari")
                ? styles.firefoxText
                : styles.chrome
            }
          >
            HACÉ CLICK EN LA PROVINCIA
            <br />Y CONOCÉ LOS FESTIVALES QUE SE VIENEN
          </h1>
        ) : (
          <h1>
            HACÉ CLICK EN LA PROVINCIA Y CONOCÉ LOS FESTIVALES QUE SE VIENEN
          </h1>
        )}
        <h2 className={styles.secondTextMobile}>{text}</h2>

        <div
          className={styles.menuButton}
          onClick={() => {
            navigate(`/quilmesfestivales/eventos`)
            window.dataLayer.push({
              event: "GAEvent",
              event_category: "Content",
              event_action: "Explora lo que se viene",
              event_label: "Ver Lista",
              interaction: true,
              component_name: "boton_ver_lista",
              element_text: "Ver Lista",
            })
          }}
        >
          <img src={cubes} alt="lista" />
          <p>VER LISTA</p>
        </div>
      </header>
      <h2 className={styles.secondText}>{text}</h2>
    </>
  )
}

export default HeaderMusicMap
