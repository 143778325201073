import React, { useState, useEffect } from "react"
import styles from "../../styles/_Map.module.scss"
import Map from "./Map"
import { IsMobileDevice } from "../../../utils/ismobile"
// import { eventData } from "../../../utils/events.json"
import HeaderMusicMap from "./mapContainer/headerMusicMap"

export default function MapSection({ provincesEvents }) {
  const events = provincesEvents
  const [isMobile, setIsMobile] = useState(false)
  const [selectedProvince, setSelectedProvince] = useState("")

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  // Sacar las columnas y centrar todo
  return (
    <div className={styles.container} id="mapaquilmes">
      <HeaderMusicMap
        provincia={false}
        text="EN TODOS LOS RINCONES DEL PAÍS"
        title="HACÉ CLICK EN LA PROVINCIA Y CONOCÉ LOS FESTIVALES QUE SE VIENEN"
      />
      <Map
        eventos={events}
        isMobile={isMobile}
        selectedProvince={selectedProvince}
        setSelectedProvince={setSelectedProvince}
      />
    </div>
  )
}
